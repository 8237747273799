// import { useEffect, useState } from "react";
import { useState, useEffect } from 'react';
import { scroller } from 'react-scroll';

function Loader() {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList.remove('scroll-disable');

    setTimeout(function () {
      const hash = window.location.hash;

      if (hash) {
        scroller.scrollTo(hash.substring(1), {
          duration: 10,
          delay: 0,
        });
      }

      setLoaded(true);
    }, 2500);
  });

  return (
    <>
      {!loaded ? (
        <div className="loader">
          <div className="loader-wrap">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 554.43 79.34" className="loader-logo">
              <path
                className="loader-logo-color01"
                d="M168.32,442.57a14.53,14.53,0,0,1-5.63,11.78,12.51,12.51,0,0,1-3.74,2.06,21.62,21.62,0,0,1-4.78,1c-1.81.18-4,.28-6.48.28H122l7.6-51.35h18.45a41.45,41.45,0,0,1,8.52.85,14.18,14.18,0,0,1,7,3.58q2.9,2.72,2.89,7.76a11.81,11.81,0,0,1-2,7,17.48,17.48,0,0,1-5.26,4.76Q168.32,434,168.32,442.57Zm-13.75-.52a8,8,0,0,0-1.12-4.57,6.13,6.13,0,0,0-3.54-2.33,24.77,24.77,0,0,0-6.56-.69h-4.78l-2.37,15.63h8.6Q154.58,450.09,154.57,442.05Zm-.92-22a6,6,0,0,0-2-4.95c-1.35-1.1-3.52-1.65-6.54-1.65h-3.4l-2,13.9h5Q153.65,427.35,153.65,420.05Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color01"
                d="M192.77,406.34l-7.67,51.35H172l7.59-51.35Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color01"
                d="M242.56,457.69H228.22L223.11,447a54.33,54.33,0,0,0-3.92-7.28,8.7,8.7,0,0,0-2.93-3,8.31,8.31,0,0,0-3.37-.57h-2l-3.26,21.56h-13l7.45-51.35h20q10.92,0,14.83,4.82a16.18,16.18,0,0,1,3.91,10.41q0,5.88-3.24,9.17a15.16,15.16,0,0,1-9.06,4.05,14,14,0,0,1,4.58,4,49.1,49.1,0,0,1,3.83,7.15ZM227.15,421.2A7.12,7.12,0,0,0,225,416q-2.16-2.2-8.76-2.21h-2L212,428.64h5.19q5.49,0,7.71-2.31A7.28,7.28,0,0,0,227.15,421.2Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color01"
                d="M295.09,431.5q0,8.55-3.26,14.37a20.84,20.84,0,0,1-9.69,8.82q-6.42,3-15.91,3H242.56l7.37-51.35h18.89a31.67,31.67,0,0,1,11.73,2,22,22,0,0,1,8.24,5.52,22.49,22.49,0,0,1,4.76,8A29.44,29.44,0,0,1,295.09,431.5Zm-13.38.18a19.73,19.73,0,0,0-1.46-7.48,13.23,13.23,0,0,0-5.39-6.17c-2.62-1.69-6.24-2.54-10.89-2.54h-2.11l-4.78,33H264a21.86,21.86,0,0,0,8.47-1.48,14,14,0,0,0,5.55-3.95,15.05,15.05,0,0,0,2.87-5.35A21.19,21.19,0,0,0,281.71,431.68Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color02"
                d="M452.66,429.09a32,32,0,0,1-2.06,11.59,26.77,26.77,0,0,1-6,9.32,27.37,27.37,0,0,1-9.37,6.17A33.61,33.61,0,0,1,411,456a21.83,21.83,0,0,1-9.76-7.61q-3.86-5.28-3.86-13.39a31,31,0,0,1,2.45-12.6,26.24,26.24,0,0,1,6.7-9.3,28.56,28.56,0,0,1,9.64-5.61,34.33,34.33,0,0,1,11.4-1.87,28.5,28.5,0,0,1,12.84,2.83,21.3,21.3,0,0,1,9,8.17A23.5,23.5,0,0,1,452.66,429.09Zm-13.75.89a14.24,14.24,0,0,0-1.52-6.75,11.08,11.08,0,0,0-4.2-4.48,11.94,11.94,0,0,0-6.2-1.59,14.16,14.16,0,0,0-8.54,2.61,16.78,16.78,0,0,0-5.47,6.59,19,19,0,0,0-1.85,8,12.77,12.77,0,0,0,3.24,8.81,11.33,11.33,0,0,0,8.91,3.63,14.8,14.8,0,0,0,6.48-1.37,15.07,15.07,0,0,0,5-3.79,16.76,16.76,0,0,0,3.13-5.45A19.13,19.13,0,0,0,438.91,430Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color02"
                d="M500.83,457.69H486.49L481.38,447a53.2,53.2,0,0,0-3.92-7.28,8.61,8.61,0,0,0-2.93-3,8.31,8.31,0,0,0-3.37-.57h-2l-3.26,21.56h-13l7.45-51.35h20q10.92,0,14.84,4.82a16.22,16.22,0,0,1,3.9,10.41q0,5.88-3.24,9.17a15.16,15.16,0,0,1-9.05,4.05,14,14,0,0,1,4.57,4,49.1,49.1,0,0,1,3.83,7.15ZM485.42,421.2a7.12,7.12,0,0,0-2.17-5.17q-2.16-2.2-8.76-2.21h-2l-2.15,14.82h5.18c3.66,0,6.22-.77,7.71-2.31A7.28,7.28,0,0,0,485.42,421.2Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color02"
                d="M544.62,406.34l-1.3,9.15H522.54L521,426.64h18l-1.3,9.19H519.5l-1.74,12.67h23.45l-1.3,9.19H503.24l7.45-51.35Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color02"
                d="M348.86,409.36l-1.66,10.19a18.21,18.21,0,0,0-2.56-1.54,26.68,26.68,0,0,0-4.21-1.74,15.22,15.22,0,0,0-4.75-.76,5.27,5.27,0,0,0-3.89,1.37,4.35,4.35,0,0,0-1.37,3.19,4.77,4.77,0,0,0,.92,2.87,32.34,32.34,0,0,0,4.78,4.43q5.7,4.54,7.85,7.94a14,14,0,0,1,2.15,7.69,16.61,16.61,0,0,1-1.63,7,13.74,13.74,0,0,1-5.57,5.92q-3.94,2.42-10.47,2.41a31.18,31.18,0,0,1-9.23-1.35,30.5,30.5,0,0,1-7.14-3.09l1.59-10.41q8,5,13,5,3.14,0,4.46-1.74a6.13,6.13,0,0,0,1.31-3.78,5.94,5.94,0,0,0-1.42-3.95q-1.42-1.68-6.76-6.54a38.23,38.23,0,0,1-4.89-5.13,12.16,12.16,0,0,1-2.17-3.92,14.49,14.49,0,0,1-.46-3.84,13.51,13.51,0,0,1,1-4.94,13.05,13.05,0,0,1,3-4.52,14.65,14.65,0,0,1,5.26-3.28,20.91,20.91,0,0,1,7.5-1.22A34.8,34.8,0,0,1,348.86,409.36Z"
                transform="translate(-22.81 -379.03)"
              />
              <line className="loader-logo-color03" x1="169.96" y1="27.31" x2="162.29" y2="78.66" />
              <path
                className="loader-logo-color02"
                d="M370.51,445.2a11.63,11.63,0,0,1-4.53-4.59,13.46,13.46,0,0,1-1.64-6.65,16.13,16.13,0,0,1,5.6-12.36,18.93,18.93,0,0,1,6.06-3.52,21,21,0,0,1,7.09-1.2,33.74,33.74,0,0,1,3.78.22c1.33.15,2.73.38,4.18.69a43.49,43.49,0,0,1,4.56,1.24l2.19-11.56q-4.68-1-7.6-1.39a48,48,0,0,0-6.74-.42,38.85,38.85,0,0,0-15.93,2.92,26.9,26.9,0,0,0-10.17,7.47,28.59,28.59,0,0,0-5.18,9.48,31.86,31.86,0,0,0-1.54,9.5,23.89,23.89,0,0,0,2,10,20.42,20.42,0,0,0,5.67,7.42,24.24,24.24,0,0,0,8.24,4.49,32.19,32.19,0,0,0,9.72,1.44,31.41,31.41,0,0,0,5-.39,34.75,34.75,0,0,0,4-.85c1.06-.31,2.35-.75,3.89-1.31,1.22-.46,2.34-.89,3.36-1.3l2-13.74q-9.06,6.08-17.58,6.07A12.8,12.8,0,0,1,370.51,445.2Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color04"
                d="M560.38,444.67c-.93-4.06-2.27-10-2.47-10.76,0-.18-.16-.44-.31-.49l-.2-.07c-1.52-.54-3.56-1-5.16-1.59.1.32.16.51.23.7.84,2.18,4.23,10.93,5.58,14.36A8.24,8.24,0,0,1,560.38,444.67Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color04"
                d="M564.74,443.42a8.25,8.25,0,0,1,2.78.49c.62-3.69,1.42-8.41,1.62-10.06a1,1,0,0,0-.33-.78c-.81-.71-4-3.23-4.07-3.26s-3.26,2.55-4.07,3.26a1,1,0,0,0-.32.78c.19,1.65,1,6.37,1.61,10.06A8.25,8.25,0,0,1,564.74,443.42Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color04"
                d="M572.08,433.35l-.2.07c-.15.05-.27.31-.31.49-.19.8-1.54,6.7-2.47,10.76a8.42,8.42,0,0,1,2.34,2.15c1.34-3.43,4.73-12.18,5.57-14.36.07-.19.13-.38.23-.7C575.64,432.31,573.6,432.81,572.08,433.35Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color04"
                d="M568.55,447.1a6,6,0,0,0-1.42-.86,6,6,0,0,0-4.78,0,6,6,0,0,0-1.42.86,6.37,6.37,0,0,0-1.22,1.37,6,6,0,1,0,10.06,0A6.37,6.37,0,0,0,568.55,447.1Z"
                transform="translate(-22.81 -379.03)"
              />
              <path
                className="loader-logo-color02"
                d="M102.44,422.9a17.36,17.36,0,0,0-17.13-17.34l-3.43-.34-1.12-.2a21.59,21.59,0,0,0,3.39-12.42c0-8.12-4.52-13.57-11.23-13.57s-11.23,5.45-11.23,13.57A21.59,21.59,0,0,0,65.07,405a32.13,32.13,0,0,1-4.46.53c-9.09.06-15.83,6.64-17.19,16.83l-1.21,9.8L35,425.27H22.81l6.33,6.1h0L48.53,450l3.3-26.55c.37-2.84,2-9.44,8.91-9.44H61a33.68,33.68,0,0,0,12-2.62,28.38,28.38,0,0,0,6.32,2v.09a37.35,37.35,0,0,0,5.65.58h.21A8.89,8.89,0,0,1,94,422.9v17.64H88.78V432.3H56.72v25.3H88.78V449h13.66ZM72.92,401.26c-2.29-2.43-2.76-5.58-2.76-8.66,0-.85.13-5.1,2.76-5.1s2.76,4.25,2.76,5.1C75.68,395.68,75.2,398.83,72.92,401.26Zm-7.73,47.87v-8.36H80.31v8.36Z"
                transform="translate(-22.81 -379.03)"
              />
            </svg>
          </div>
          <div className="loader-background"></div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
export default Loader;
