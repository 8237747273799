function Caution() {
  return (
    <section className="caution">
      <div className="container caution-container">
        <ul className="caution-list">
          <li>BIRD SCOREのご利用には、複数の機器が同時アクセスできる安定したインターネット接続環境が必要です。</li>
          <li>BIRD SCOREの全てのシステムは、Google Chromeに最適化されています。</li>
          <li>画像は全てイメージです。システムの改善などにより一部表示が予告なく変更される場合がございます。予めご了承ください。</li>
        </ul>
      </div>
    </section>
  )
}

export default Caution;