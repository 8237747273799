import { format, isAfter, isBefore, isSameDay } from "date-fns";

function formatDateValue(date?: string | Date): string {
  let dateString: string;

  if (date instanceof Date) {
    dateString = date.toISOString().split("T")[0];
  } else if (typeof date === "string") {
    dateString = date;
  } else {
    dateString = new Date().toISOString().split("T")[0];
  }

  const [year, month, day] = dateString.split("-");
  const formattedMonth = month.padStart(2, "0");
  const formattedDay = day.padStart(2, "0");

  return `${year}-${formattedMonth}-${formattedDay}`;
}

function dateFormat(date?: string | Date, compareDate?: string | Date) {
  const dateValue = formatDateValue(date);
  const dateSet = date && !isNaN(new Date(dateValue).getTime()) ? new Date(dateValue) : new Date();
  const compareDateSet = compareDate && !isNaN(new Date(compareDate).getTime()) ? new Date(compareDate) : new Date();
  const formattedDate = format(dateSet, "yyyy年MM月dd日");
  const isTodayOrLater = isSameDay(dateSet, compareDateSet) || isAfter(dateSet, compareDateSet);
  const isTodayOrBefore = isSameDay(dateSet, compareDateSet) || isBefore(dateSet, compareDateSet);
  const isBeforeDate = !isSameDay(dateSet, compareDateSet) && isBefore(dateSet, compareDateSet);

  return {
    formattedDate,
    isAfter: isAfter(dateSet, compareDateSet),
    isBefore: isBefore(dateSet, compareDateSet),
    isTodayOrLater,
    isTodayOrBefore,
    isBeforeDate,
  };
}

export default dateFormat;
