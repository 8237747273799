import refereeImage from 'assets/images/birdscore_referee_mockup.png';

type TabletContentProps = {
  name?: string;
  children?: React.ReactNode;
  activeAnim?: boolean;
  animationControl: () => void;
  leftScore: () => void;
  rightScore: () => void;
  scoreControl: {
    scoreA: number;
    scoreB: number;
  };
  receiverControl: {
    left: boolean;
    right: boolean;
  };
  bigger: boolean;
};

function TabletContent({
  name,
  children,
  activeAnim,
  animationControl,
  leftScore,
  rightScore,
  scoreControl,
  receiverControl,
  bigger,
}: TabletContentProps) {
  return (
    <section className={name}>
      <div className={'container ' + name + '-container' + (bigger ? ' bigger' : '')}>
        {children}
        <div className={name + '-content'}>
          <div className={name + '-content-score'}>
            <p className={receiverControl.left ? name + '-content-score-left active' : name + '-content-score-left'}>
              {scoreControl?.scoreB}
            </p>
            <span className={name + '-content-score-center'}></span>
            <p className={receiverControl.right ? name + '-content-score-right active' : name + '-content-score-right'}>
              {scoreControl?.scoreA}
            </p>
          </div>
          <div onMouseEnter={animationControl} onMouseLeave={animationControl}>
            <div className={!activeAnim ? name + '-content-buttons active' : name + '-content-buttons'}>
              <button className={name + '-content-buttons-button left'} onClick={leftScore}>
                <span>score</span>
              </button>
              <button className={name + '-content-buttons-button right'} onClick={rightScore}>
                <span>score</span>
              </button>
            </div>
            {name === 'tablet' ? (
              <div className={'tablet-content-hands' + (!activeAnim ? ' hidden' : '')}>
                <span className="tablet-content-hands-icon left"></span>
                <span className="tablet-content-hands-icon right"></span>
              </div>
            ) : (
              ''
            )}
          </div>
          <figure className={name + '-content-image'}>
            <img src={refereeImage} alt="BIRD SCORE タブレット 審判入力画面 例" />
            <figcaption className={name + '-content-image-caption'}>
              <span>BIRD SCOREは、iPad®（第7世代以降モデル）に最適化されています。</span>
              <span>リースについてもご相談承ります。お気軽にお問い合わせください。</span>
              <span>iPad®は、Apple Inc.の商標です。</span>
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
}

export default TabletContent;
