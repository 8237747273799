type props = {
  className: string;
  margin?: number;
  direction?: string;
  onClick: () => void;
};

function SlideArrow(props: props) {
  const { className, onClick, margin, direction } = props;

  return (
    <button
      className={className}
      onClick={onClick}
      style={direction === 'left' ? { marginLeft: margin + 'px' } : { marginLeft: '-' + margin + 'px' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15">
        <path
          d="M19.418,18.9l.982-1.069L14.382,11.4,20.4,4.969,19.418,3.9,12.4,11.4l3.949,4.221Z"
          transform="translate(-12.4 -3.9)"
        />
      </svg>
    </button>
  );
}

export default SlideArrow;
