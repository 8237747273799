export const Success = () => {
  return (
    <>
      <h2 className="section-title contact-submit-title">
        お問い合わせいただき、
        <br />
        誠にありがとうございました。
      </h2>
      <p className="section-description contact-submit-description">
        お問い合わせ内容の送信が完了した時点で
        <br className="visible-pc" />
        自動返信メールを送信しておりますのでご確認ください。
        <br />
        しばらく経っても自動返信メールが届かない場合は、
        <br className="visible-pc" />
        下記のような原因によりお客様が受け取れない場合もございますので、
        <br className="visible-pc" />
        一度設定をご確認くださいますようお願い申しあげます。
      </p>
      <ul className="section-description contact-submit-list">
        <li>迷惑メールと誤判断され迷惑メールフォルダ・削除フォルダに隔離されている</li>
        <li>お問い合せ時のアドレスの入力間違い</li>
        <li>メールサーバーの容量を超えている</li>
        <li>携帯のドメイン指定受信拒否を設定されている</li>
      </ul>
      <p className="section-description contact-submit-description">
        上記をご確認いただいてもメールが届かないようでしたら、
        <br className="visible-pc" />
        まことにお手数ですが、フォームにお戻りいただき、
        <br className="visible-pc" />
        もう一度お問い合わせくださいますようお願い申し上げます。
      </p>
    </>
  );
};
export const Failed = () => {
  return (
    <>
      <h2 className="section-title contact-submit-title">メール送信に失敗しました。</h2>
      <p className="section-description contact-submit-description">
        何らかの理由でメール送信に失敗しました。
        <br />
        フォームにお戻りいただき、
        <br className="visible-pc" />
        もう一度お問い合わせくださいますようお願い申し上げます。
      </p>
      <p className="section-description contact-submit-description">
        もし、繰り返しエラーが発生するようでしたら、
        <br className="visible-pc" />
        まことにお手数ですが、以下のメールアドレスに
        <br className="visible-pc" />
        ご連絡くださいますようお願い申し上げます。
      </p>
      <p className="section-description contact-submit-list" style={{ textAlign: 'center' }}>
        BIRD SCORE システム管理担当 : <a href="mailto:birdscore_info@technonet.co.jp">birdscore_info@technonet.co.jp</a>
      </p>
    </>
  );
};
