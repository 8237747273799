import { useEffect, useState } from "react";

import SlideArrow from "components/slider/Arrows";
import Slider from "react-slick";
import dateFormat from "utils/dateFormat";
import { matchData } from "data/matchData";

function Works() {
  const [centerControl, setCenterControl] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const worksData = matchData.filter((match) => {
    const today = new Date();
    const isFinished = dateFormat(match.finishDate, today).isBeforeDate;
    return isFinished;
  });

  const slideLength = worksData.length;
  const arrowMargin = slideLength * 15;
  const dotsSet = slideLength > 10 ? false : true;

  const settings = {
    centerMode: true,
    centerPadding: "0",
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    swipeToSlide: true,
    variableWidth: true,
    focusOnSelect: true,
    prevArrow: (
      <SlideArrow className="prev" direction="right" margin={arrowMargin + 10} onClick={() => Slider.bind("")} />
    ),
    nextArrow: <SlideArrow className="next" direction="left" margin={arrowMargin} onClick={() => Slider.bind("")} />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: dotsSet,
          beforeChange: (current: number, next: number) => setCurrentSlide(next),
        },
      },
    ],
  };

  useEffect(() => {
    if (slideLength < 2) {
      setCenterControl(true);
    }
  }, [slideLength]);

  return (
    <section className="works" id="works">
      <div className="works-wrap">
        <h2 className="section-title works-title">
          BIRD SCORE
          <br />
          運用実績のご紹介
        </h2>
        <p className="section-description works-description">
          実際にBIRD SCOREをご利用いただいた
          <br />
          バドミントン大会をご紹介します。
        </p>
      </div>
      <Slider
        {...settings}
        className={"works-slide" + (centerControl ? " unslick" : "") + (slideLength > 10 ? " max" : "")}
      >
        {worksData.slice(0, 10).map((item, index) => {
          const linkInfo: string[][] = item.links;
          return (
            <div className="works-slide-item" key={index}>
              <div className="works-slide-item-header">
                <h3 className="works-slide-item-title">
                  <span>{item.title}</span>
                </h3>
              </div>
              <div className="works-slide-item-content">
                <ul className="works-slide-item-detail">
                  <li className="works-slide-item-detail-date">
                    <p className="data">
                      {dateFormat(item.startDate).formattedDate} 〜 {dateFormat(item.finishDate).formattedDate}
                    </p>
                  </li>
                  <li className="works-slide-item-detail-place">
                    <p className="data">{item.place}</p>
                  </li>
                </ul>

                <ul className="works-slide-item-links">
                  {linkInfo.map((list, listIndex) => {
                    let subClass = "";
                    if (linkInfo.length <= 2) {
                      subClass = " item-wide";
                    }
                    return (
                      <li className={"item" + subClass} key={listIndex}>
                        <a href={list[1]} target={`_blank`}>
                          <span>{list[0]}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </Slider>
      {slideLength > 10 ? (
        <div className="works-slide-page">
          <p className="works-slide-page-current">{currentSlide + 1}</p>
          <p className="works-slide-page-all">{slideLength > 10 ? 10 : slideLength}</p>
        </div>
      ) : null}
    </section>
  );
}

export default Works;
