import Close from './Close';
import LiveScore from './LiveScore';
import Shuttle from './Shuttle';
import { svgProps } from 'types/svgTypes';

function SvgModule(props: svgProps) {
  const svg: { [key: string]: JSX.Element } = {
    liveScore: <LiveScore fill={props.fill} className={props.className} />,
    close: <Close fill={props.fill} className={props.className} />,
    shuttle: <Shuttle fill={props.fill} className={props.className} />,
  };
  return props.type ? svg[props.type] : null;
}

export default SvgModule;
