import { useState, useCallback, ChangeEvent, useRef } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PrivacyPolicy from 'components/form/Form-Policy';
import { Success, Failed } from 'components/form/Form-Results';
import axios from 'axios';
import Reaptcha from 'reaptcha';
import { SENDMAIL_API_KEY, SENDMAIL_URL, RECAPTCHA_SITE_KEY } from 'config/env';

type FormValues = {
  name: string;
  email: string;
  inquiry: string;
  agreement: boolean;
  recaptchaToken: string;
};

const defaultValues = {
  name: '',
  email: '',
  inquiry: '',
  agreement: true,
  recaptchaToken: '',
};

const schema = yup.object({
  name: yup.string().required('必ずご入力ください。'),
  email: yup.string().required('必ずご入力ください。').email('メール形式が正しくありません。'),
  inquiry: yup.string().required('必ずご入力ください。'),
});

function Contact() {
  // 個人情報取り扱い
  const [isAgree, setIsAgree] = useState<boolean>(false);
  //　reCaptcha
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const recaptchaRef = useRef(null);

  const onVerify = (recaptchaResponse: string) => {
    setIsVerified(true);
    setRecaptchaToken(recaptchaResponse);
  };

  const SubmitResult = (submit: boolean, result: boolean) => {
    setIsSubmit(submit);
    setIsSuccess(result);
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues, resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      data.recaptchaToken = recaptchaToken ? recaptchaToken : '';

      await axios.post<[]>(SENDMAIL_URL, data, {
        headers: {
          'x-api-key': SENDMAIL_API_KEY,
        },
      });

      SubmitResult(true, true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log('Axios Error');
        if (e.request) {
          console.log(e.request);
        } else if (e.response) {
          console.log(e.response.data);
          console.log(e.response.status);
          console.log(e.response.headers);
        } else {
          console.log(e.message);
        }
        console.log(e.toJSON());
      } else {
        console.log('Unexpected Error');
      }

      SubmitResult(true, false);
    }
  };

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsAgree((current) => !current);
  }, []);

  return (
    <section className="contact" id="contact">
      {!isSubmit ? (
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="contact-form-content">
            <div className="contact-form-wrap">
              <h2 className="section-title">
                BIRD SCOREについて
                <br />
                ご相談・ご質問など
                <br />
                お気軽に
                <br className="visible-sp" />
                お問い合わせください。
              </h2>
              <p className="section-description">
                フォームの必要事項をご入力の上、
                <br className="visible-sp" />
                ご送信ください。
                <br />
                追って担当者より回答させていただきます。
              </p>
            </div>
            <div className="contact-form-policy">
              <PrivacyPolicy />
            </div>
            <div className="contact-form-detail">
              <TextField
                id="name"
                label="お名前"
                required
                fullWidth
                variant="filled"
                {...register('name')}
                error={'name' in errors}
                helperText={errors.name?.message}
              />
              <TextField
                id="email"
                label="メールアドレス"
                required
                fullWidth
                variant="filled"
                type="email"
                {...register('email')}
                error={'email' in errors}
                helperText={errors.email?.message}
              />
              <TextField
                id="inquiry"
                label="お問い合わせ内容"
                required
                fullWidth
                multiline
                rows={7}
                variant="filled"
                {...register('inquiry')}
                error={'inquiry' in errors}
                helperText={errors.inquiry?.message}
              />
            </div>
          </div>

          <div className="contact-form-footer">
            <div className="contact-form-agreement">
              <Controller
                name="agreement"
                control={control}
                rules={{ required: true }}
                render={({ field }): JSX.Element => (
                  <FormControlLabel
                    control={<Checkbox {...field} onChange={onChange} checked={isAgree} />}
                    label="個人情報取り扱いに同意する"
                  />
                )}
              />
            </div>

            <Reaptcha ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onVerify={onVerify} />

            <Button
              size="large"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isAgree || !isVerified}
            >
              送信する
            </Button>
          </div>
        </form>
      ) : (
        <div className="container contact-submit">
          {isSuccess ? <Success /> : <Failed />}
          <div className="contact-submit-footer">
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                SubmitResult(false, false);
                setIsVerified(false);
              }}
            >
              フォームに戻る
            </Button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Contact;
