import laptopImage from 'assets/images/birdscore_admin_mockup01.png';
import monitorImage from 'assets/images/birdscore_admin_mockup02.png';
import { useState, useEffect } from 'react';

function Admin() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, [isMobile]);

  return (
    <section className="admin">
      <div className="container admin-container">
        <div className="admin-wrap">
          <h2 className="section-title admin-title">
            大会登録から
            <br />
            速報ページの公開まで
            <br />
            <strong>管理画面</strong>でサポート
          </h2>
          <p className="section-description admin-description">
            BIRD SCOREなら
            <br />
            コートサイド・場内モニターの表示をはじめ、
            <br />
            ライブスコアや試合結果、
            <br />
            試合スケジュールの表示設定など、
            <br />
            PCから簡単に管理することができます。
          </p>
        </div>
      </div>

      <div className="admin-content">
        <figure className="admin-content-image laptop">
          <img src={laptopImage} alt="BIRD SCORE 管理者画面" />
        </figure>
        <figure className="admin-content-image desktop">
          <img src={monitorImage} alt="BIRD SCORE 管理者画面" />
        </figure>
      </div>
    </section>
  );
}

export default Admin;
