import { svgProps } from 'types/svgTypes';

function Shuttle(props: svgProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.886 15.517" className={props.className}>
      <path
        d="M681.614,197.632c-.513-2.252-1.261-5.531-1.369-5.977-.024-.1-.091-.241-.171-.272-.037-.012-.075-.027-.113-.038-.845-.3-1.975-.577-2.866-.885.058.178.088.286.127.392.469,1.213,2.353,6.072,3.1,7.975A4.663,4.663,0,0,1,681.614,197.632Z"
        transform="translate(-677.095 -189.378)"
        fill={props.fill}
      />
      <path
        d="M687.64,196.073a4.6,4.6,0,0,1,1.544.271c.345-2.051.79-4.673.9-5.585a.584.584,0,0,0-.18-.435c-.45-.4-2.242-1.792-2.262-1.811-.02.019-1.812,1.415-2.262,1.811a.581.581,0,0,0-.179.435c.108.912.553,3.534.9,5.585A4.607,4.607,0,0,1,687.64,196.073Z"
        transform="translate(-680.696 -188.512)"
        fill={props.fill}
      />
      <path
        d="M695.612,191.344c-.037.011-.075.027-.113.038-.081.031-.147.171-.171.272-.109.446-.857,3.725-1.37,5.977a4.66,4.66,0,0,1,1.3,1.195c.745-1.9,2.627-6.763,3.1-7.976.039-.106.068-.213.126-.392C697.586,190.768,696.457,191.048,695.612,191.344Z"
        transform="translate(-684.591 -189.378)"
        fill={props.fill}
      />
      <path
        d="M689.042,205.2a3.35,3.35,0,1,0,.678.76A3.331,3.331,0,0,0,689.042,205.2Z"
        transform="translate(-679.984 -195.595)"
        fill={props.fill}
      />
    </svg>
  );
}

export default Shuttle;
