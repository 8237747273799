import { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import TabletContent from 'components/contents/Content-Tablet';
import Admin from 'components/contents/Content-Admin';
import Monitor from 'components/contents/Content-Monitor';
import Web from 'components/contents/Content-Web';
import { SetAnimation } from 'components/contents/Content-Animation';

function Contents() {
  const [score, setScore] = useState({ scoreA: 5, scoreB: 3 });
  const [receiver, setReceiver] = useState({ left: true, right: false });
  const [activeAnim, setActiveAnim] = useState<boolean>(true);

  const handleLeftScore = () => {
    if (score.scoreB < 21 && score.scoreA < 21) {
      setScore((prevState) => ({ ...prevState, scoreB: score.scoreB + 1 }));
      if (receiver.right) {
        setReceiver(() => ({ left: true, right: false }));
      } else {
        setReceiver((prevState) => ({ ...prevState, left: true }));
      }
    } else if (score.scoreB === 21 || score.scoreA === 21) {
      setActiveAnim(false);
    }
  };

  const handleRightScore = () => {
    if (score.scoreA < 21 && score.scoreB < 21) {
      setScore((prevState) => ({ ...prevState, scoreA: score.scoreA + 1 }));
      if (receiver.left) {
        setReceiver(() => ({ left: false, right: true }));
      } else {
        setReceiver((prevState) => ({ ...prevState, right: true }));
      }
    } else if (score.scoreB === 21 || score.scoreA === 21) {
      setActiveAnim(false);
    }
  };

  const animationControl = () => {
    activeAnim || score.scoreB === 21 || score.scoreA === 21 ? setActiveAnim(false) : setActiveAnim(true);
  };

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.refresh();
    SetAnimation();
  });

  return (
    <>
      <TabletContent
        name="referee"
        animationControl={animationControl}
        receiverControl={receiver}
        scoreControl={score}
        leftScore={handleLeftScore}
        rightScore={handleRightScore}
        activeAnim={activeAnim}
        bigger={false}
      >
        <div className="referee-wrap">
          <h2 className="section-title referee-title">
            スコア入力は
            <br />
            タブレットで簡単に
          </h2>
          <p className="section-description referee-description">
            シンプルでわかりやすい画面で
            <br />
            ボタンをタップするだけ。
          </p>
        </div>
      </TabletContent>

      <Admin />

      <TabletContent
        name="tablet"
        animationControl={animationControl}
        receiverControl={receiver}
        scoreControl={score}
        leftScore={handleLeftScore}
        rightScore={handleRightScore}
        activeAnim={activeAnim}
        bigger={true}
      />

      <Monitor scoreControl={score} />

      <Web />
    </>
  );
}

export default Contents;
