import { svgProps } from 'types/svgTypes';

function LiveScore(props: svgProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505.195 78.35" className={props.className}>
      <g transform="translate(-874.541 -214.958)">
        <path
          d="M1160.017,214.051H1118.16l-32.509,78.3h41.86ZM1118.96,279.54l.007.01h-14.151l21.88-52.7h14.155Z"
          transform="translate(109.488 0.907)"
          fill={props.fill}
        />
        <path
          d="M1072.325,214.051l0,.023h-27.785l-18.917,45.567h27.941l-8.279,19.943h-14.057l4.14-9.971h-13.9l-4.139,9.971,8.6,12.813h27.957l18.917-45.567h-27.941l8.279-19.943h13.933l-4.14,9.971h13.9l4.135-9.959h.11Z"
          transform="translate(80.527 0.907)"
          fill={props.fill}
        />
        <path
          d="M1230.529,226.863l5.329-12.812h-41.881l-27.2,65.51,8.5,12.807v.006h28.052l5.319-12.812h-28.018l8.284-19.954h18.255l5.319-12.812h-18.255l8.274-19.931Z"
          transform="translate(143.879 0.907)"
          fill={props.fill}
        />
        <path
          d="M1115.2,226.885h0l-3.986-12.81-32.559-.023-27.206,65.533,8.5,12.813H1088l9.48-22.835h-13.867l-4.161,10.022H1065.3l21.877-52.7h14.159l-4.135,9.953h13.864l4.131-9.953h0Z"
          transform="translate(94.989 0.907)"
          fill={props.fill}
        />
        <path
          d="M1183.3,214.051H1155.29l-27.195,65.509,8.5,12.806v.006h0l13.632-32.767h2.8L1160,270.171l-3.91,9.411,8.558,12.819,9.465-22.789,0-.006,0-.007-6.607-9.993h10.749l13.6-32.717Zm-13.587,32.742h-14.165l8.274-19.93H1178Z"
          transform="translate(127.48 0.907)"
          fill={props.fill}
        />
        <path
          d="M1033.594,227.006l5.354-12.8-41.881-.081-27.322,65.457L978.22,292.4v.006h0l28.048.054,5.344-12.8-28.018-.054,8.322-19.938,18.255.035,5.344-12.8-18.255-.035,8.313-19.915Z"
          transform="translate(40.356 0.834)"
          fill={props.fill}
        />
        <path
          d="M951.326,214.008l-13.855-.027-15.86,38-11.482,27.489,8.533,12.835,27.33-65.488-.007-.01Z"
          transform="translate(15.086 0.976)"
          fill={props.fill}
        />
        <path
          d="M998.17,214.135l-13.768-.027-21.734,51.949-9.816,6.585-4.358-6.577,21.749-51.984-13.936-.027-17.6,42.121h0l-6.975,16.692,12.927,19.51,28.964-19.429,6.975-16.692-.01.007Z"
          transform="translate(24.242 0.903)"
          fill={props.fill}
        />
        <path
          d="M920.564,268.543l-13.9-.027-4.154,9.952-14.12-.027,8.322-19.938,5.344-12.8,13.658-32.717-13.855-.027-27.322,65.457,8.475,12.823v.006h0l28.048.054,5.344-12.8h0Z"
          transform="translate(0 2)"
          fill={props.fill}
        />
      </g>
    </svg>
  );
}

export default LiveScore;
