import Features from 'components/slider/Slide-Features'
import Works from 'components/slider/Slide-Works'

function SliderContents() {
  return (
    <>
      <Features />
      <Works />
    </>
  )
}

export default SliderContents;