import "assets/scss/Index.scss";

import Caution from "components/Caution";
import Contact from "components/form/Form-Contact";
import Contents from "components/contents/Contents";
import Floating from "components/Floating";
import Footer from "components/Footer";
import Header from "components/Header";
import Loader from "components/Loader";
import Main from "components/Main";
import React from "react";
import ReactDOM from "react-dom/client";
import SliderContents from "components/slider/Sliders";

function Index() {
  return (
    <>
      <Loader />
      <Header />
      <Main />
      <div className="gradient">
        <Contents />
        <SliderContents />
      </div>
      <Contact />
      <Caution />
      <Footer />
      <Floating />
      <div className="main-background"></div>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
);
