import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Logo from "assets/images/logos/logo_normal.svg";

function Header() {
  const [activeToggle, setActiveToggle] = useState<boolean>(false);
  const [fixHeader, setFixHeader] = useState<boolean>(false);
  const [fixNav, setFixNav] = useState<boolean>(false);
  const [linksOffset, setLinksOffset] = useState<number>(0);

  useEffect(() => {
    setLinkOffset();
    setNavFix();
    document.addEventListener("scroll", getPosition);
    document.addEventListener("scroll", () => {
      setActiveToggle(false);
    });
    document.addEventListener("resize", setLinkOffset);
    document.addEventListener("resize", setNavFix);

    return (): void => {
      document.removeEventListener("scroll", getPosition);
      document.removeEventListener("resize", setLinkOffset);
      document.removeEventListener("resize", setNavFix);
      document.removeEventListener("scroll", () => {
        setActiveToggle(false);
      });
    };
  });

  const getPosition = (): void => {
    const position = window.pageYOffset;
    const viewHeight = window.innerHeight;
    position > viewHeight * 0.8 ? setFixHeader(true) : setFixHeader(false);
  };

  const setNavFix = (): void => {
    const viewWidth = window.innerWidth;
    viewWidth < 1281 ? setFixNav(true) : setFixNav(false);
  };

  const setLinkOffset = (): void => {
    const viewHeight = window.innerHeight;
    const defaultOffset = 200 / 1080;
    const calcOffset = -(viewHeight * defaultOffset);
    setLinksOffset(calcOffset);
  };

  return (
    <header className={"header" + (fixHeader ? " fixed" : "")}>
      <p className="header-logo">
        <Link to="main" spy={true} smooth={true} duration={500}>
          <img src={Logo} alt="BIRD SCORE" />
        </Link>
      </p>

      <div
        className={"header-toggle" + (activeToggle ? " active" : "")}
        onClick={() => {
          setActiveToggle(!activeToggle);
        }}
      >
        <p className="header-toggle-button">
          <span className="top"></span>
          <span className="middle"></span>
          <span className="bottom"></span>
        </p>
      </div>

      <nav
        className={
          "header-navigation" +
          (activeToggle ? " active" : "") +
          (fixNav ? " fixed" : "")
        }
      >
        <ul className="header-navigation-list">
          <li className="header-navigation-list-item">
            <Link
              to="referee"
              spy={true}
              smooth={true}
              duration={500}
              offset={linksOffset}
            >
              <span>BIRD SCORE</span>について
            </Link>
          </li>
          <li className="header-navigation-list-item">
            <Link
              to="works"
              spy={true}
              smooth={true}
              duration={500}
              offset={linksOffset}
            >
              <span>運営</span>実績
            </Link>
          </li>
          <li className="header-navigation-list-item">
            <Link to="contact" spy={true} smooth={true} duration={500}>
              <span>お問い</span>合わせ
            </Link>
          </li>
        </ul>

        <p className="header-navigation-logo">
          <a href="/">
            <img src={Logo} alt="BIRD SCORE" />
          </a>
        </p>
      </nav>
      {activeToggle ? (
        <div
          className="toggleWrapper"
          onClick={() => setActiveToggle(!activeToggle)}
        ></div>
      ) : (
        ""
      )}
    </header>
  );
}

export default Header;
