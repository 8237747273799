import { ScrollTrigger } from "gsap/all";
import { gsap } from "gsap";

export function SetAnimation(this: any) {
  ScrollTrigger.matchMedia({
    "(max-width: 767px)": function () {
      ScrollTrigger.create({
        trigger: ".contact",
        onEnter: () => {
          document.querySelectorAll(".footer-fixed, .floating-button").forEach((element) => {
            element.classList.add("min");
          });
        },
        onLeaveBack: () => {
          document.querySelectorAll(".footer-fixed, .floating-button").forEach((element) => {
            element.classList.remove("min");
          });
        },
      });
    },
  });

  ScrollTrigger.create({
    trigger: ".referee",
    onUpdate: (self) => {
      if (self.progress > 0.2) {
        document.querySelector(".referee-content")?.classList.add("active");
      } else {
        document.querySelector(".referee-content")?.classList.remove("active");
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".admin",
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelectorAll(".admin-wrap,.admin-content-image").forEach((element) => {
          element.classList.add("active");
        });
      } else {
        document.querySelectorAll(".admin-wrap,.admin-content-image").forEach((element) => {
          element.classList.remove("active");
        });
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".tablet",
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelector(".tablet-content")?.classList.add("active");
      } else {
        document.querySelector(".tablet-content")?.classList.remove("active");
      }
      if (self.progress > 0.2) {
        document.querySelector(".tablet-content-hands")?.classList.add("active");
      } else {
        document.querySelector(".tablet-content-hands")?.classList.remove("active");
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".monitor",
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelectorAll(".monitor-animate").forEach((element) => {
          element.classList.add("active");
        });
      } else {
        document.querySelectorAll(".monitor-animate").forEach((element) => {
          element.classList.remove("active");
        });
      }

      if (self.progress > 0.5) {
        gsap.to(".header-logo a", { background: "rgba(255,255,255,0.8)" });
      } else {
        gsap.to(".header-logo a", { clearProps: "all" });
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".web",
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelectorAll(".web-animate").forEach((element) => {
          element.classList.add("active");
        });
      } else {
        document.querySelectorAll(".web-animate").forEach((element) => {
          element.classList.remove("active");
        });
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".feature",
    onEnter: () => {
      gsap.to(".header-toggle-button span", { background: "#1F318E" });
    },
    onLeaveBack: () => {
      gsap.to(".header-toggle-button span", { clearProps: "all" });
    },
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelector(".feature-title")?.classList.add("active");
      } else {
        document.querySelector(".feature-title")?.classList.remove("active");
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".works",
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelector(".works-wrap")?.classList.add("active");
      } else {
        document.querySelector(".works-wrap")?.classList.remove("active");
      }
    },
  });

  ScrollTrigger.create({
    trigger: ".contact",
    onUpdate: (self) => {
      if (self.progress > 0.01) {
        document.querySelector(".contact-form-wrap")?.classList.add("active");
        gsap.to(".header-logo, .footer-fixed, .floating-button", 0.2, { autoAlpha: 0 });
      } else {
        document.querySelector(".contact-form-wrap")?.classList.remove("active");
        gsap.to(".header-logo, .footer-fixed, .floating-button", 0.2, { autoAlpha: 1 });
      }
    },
  });
  ScrollTrigger.create({
    trigger: ".footer",
    onUpdate: (self) => {
      if (self.progress > 0.1) {
        document.querySelectorAll(".footer-fixed, .floating-button").forEach((element) => {
          element.classList.add("min");
        });
        gsap.to(".footer-fixed, .floating-button", 0.2, { autoAlpha: 1 });
      }
    },
  });
}
