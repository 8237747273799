import { Link } from 'react-scroll';
import logoWhite from 'assets/images/logos/logo_white.svg';
import logoTechnonet from 'assets/images/logos/logo_technonet.svg';
import logoTmony from 'assets/images/logos/logo_tmony.svg';
import iconMail from 'assets/images/icons/icon_mail.svg';

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <h2 className="footer-title">
            <Link to="main">
              <span>登録商標第6461922号</span>
              <img src={logoWhite} alt="BIRD SCORE（バード スコア）" />
            </Link>
          </h2>
          <ul className="footer-links">
            <li className="footer-links-item technonet">
              <a href="https://technonet.co.jp/" target={`_blank`}>
                <img src={logoTechnonet} alt="株式会社テクノネット" />
              </a>
            </li>
            <li className="footer-links-item tmony">
              <a href="https://www.smash-net.tv/" target={`_blank`}>
                <img src={logoTmony} alt="株式会社TMONY" />
              </a>
            </li>
          </ul>
          <p className="footer-copyright">&copy; TECHNONET CO.,LTD.</p>
        </div>
      </footer>
      <Link to="contact" className="footer-fixed" spy={true} smooth={true} duration={500}>
        <img src={iconMail} alt="バード スコア お問い合わせ" />
        <span>お問い合わせ</span>
      </Link>
    </>
  );
}

export default Footer;
