import webLaptopImage from 'assets/images/birdscore_mockup_laptop.png';
import webLaptopInnerImage from 'assets/images/birdscore_web_pc.jpg';
import webMobileImage from 'assets/images/birdscore_mockup_mobile.png';
import webMobileInnerImageBlank from 'assets/images/birdscore_web_mobile_blank.jpg';
import webMobileInnerImageBefore from 'assets/images/birdscore_web_mobile_default.jpg';
import webMobileInnerImageAfter from 'assets/images/birdscore_web_mobile_detail.jpg';
import { useState, useEffect } from 'react';

function Web() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, [isMobile]);

  return (
    <section className="web">
      <div className="container web-container">
        <div className="web-content">
          <div className="web-content-laptop web-animate">
            <div className="web-content-laptop-inner">
              <img src={webLaptopInnerImage} alt="バード スコア ウェブサイトの全ページ表示例" />
            </div>
            <figure className="web-content-laptop-image">
              <img src={webLaptopImage} alt="バード スコア ウェブサイトのPC表示例" />
            </figure>
          </div>
          <div className="web-content-mobile web-animate">
            <span className="web-content-mobile-hand"></span>
            <div className="web-content-mobile-inner">
              <figure className="image-default">
                <img src={webMobileInnerImageBlank} alt="バード スコア ウェブサイトのモバイル表示例（中身）" />
              </figure>
              <figure className="image-before">
                <img src={webMobileInnerImageBefore} alt="バード スコア ウェブサイトのモバイル表示例（中身）" />
              </figure>
              <img
                src={webMobileInnerImageAfter}
                alt="バード スコア ウェブサイトの詳細スコアモバイル表示例"
                className="image-after"
              />
            </div>
            <figure className="web-content-mobile-image">
              <img src={webMobileImage} alt="バード スコア ウェブサイトのモバイル表示例" />
            </figure>
          </div>
        </div>
        <div className="web-wrap web-animate">
          <h2 className="section-title web-title">
            ライブスコアも
            <br />
            試合結果・スケジュールも
            <br />
            リアルタイムにお届け
          </h2>
          <p className="section-description">
            いつでもどこでもPCやスマートフォンで
            <br />
            大会の状況が確認できます。
          </p>
        </div>
      </div>
    </section>
  );
}

export default Web;
