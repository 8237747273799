import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import courtSideMonitorImage from "assets/images/birdscore_monitor_mockup_courtside.png";
import scoreBoardMonitorImage from "assets/images/birdscore_monitor_mockup_scoreboard.png";
import { useEffect, useState } from "react";

type scoreControlProps = {
  scoreControl: {
    scoreA: number;
    scoreB: number;
  };
};

function Monitor({ scoreControl }: scoreControlProps) {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    window.innerWidth < 1281 ? setIsMobile(true) : setIsMobile(false);
  }, [isMobile]);

  return (
    <ParallaxProvider>
      <section className="monitor">
        <Parallax
          translateY={!isMobile ? [-60, 30] : [-40, 0]}
          className="monitor-container"
        >
          <div className="monitor-wrap monitor-animate">
            <h2 className="section-title monitor-title">
              審判の入力を
              <br />
              コートサイド・
              <br />
              場内モニターに
              <br />
              リアルタイム反映
            </h2>
            <p className="section-description monitor-description">
              スコアが更新されるたびに　
              <br />
              全ての情報が即座に反映されます。
            </p>
          </div>
          <div className="monitor-content">
            <div className="monitor-content-scoreboard monitor-animate">
              <div className="monitor-content-scoreboard-score">
                <p className="playerA">{scoreControl.scoreA}</p>
                <p className="playerB">{scoreControl.scoreB}</p>
              </div>
              <figure className="monitor-content-scoreboard-image">
                <img src={scoreBoardMonitorImage} alt="場内モニター表示例" />
                <figcaption>場内モニター</figcaption>
              </figure>
            </div>
            <div className="monitor-content-courtside monitor-animate">
              <div className="monitor-content-courtside-score">
                <p className="playerA">{scoreControl.scoreA}</p>
                <p className="playerB">{scoreControl.scoreB}</p>
              </div>
              <figure className="monitor-content-courtside-image">
                <img
                  src={courtSideMonitorImage}
                  alt="コートサイドモニター表示例"
                />
                <figcaption>コートサイドモニター</figcaption>
              </figure>
            </div>
          </div>
        </Parallax>
      </section>
    </ParallaxProvider>
  );
}

export default Monitor;
