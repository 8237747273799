const PrivacyPolicy = () => {
  return (
    <>
      <h3 className="contact-form-policy-title">個人情報取り扱いについて</h3>
      <p className="contact-form-policy-description">
        バドミントン競技ライブスコアシステムBIRD SCOREは、株式会社テクノネットが開発し、バドミントン情報映像提供サイトSMASH and NET. TV を運営する株式会社TMONYがシステム運用支援、顧客サポートを行なっております。<br />
        本ウェブサイトからのお問合せにより、お客様ご本人に入力いただいた個人情報につきまして、株式会社テクノネット、株式会社TMONY（以下「テクノネット・TMONY」）は、以下の通り適正かつ安全に管理・運用することに努めます。
      </p>
      
      <h4 className="contact-form-policy-subtitle">１．利用目的</h4>
      <p className="contact-form-policy-description">テクノネット・TMONYは、収集した個人情報について、以下の目的のために利用いたします。</p>
      <ul className="contact-form-policy-list">
        <li><span>①</span> ご相談・お問合せへの回答のため</li>
        <li><span>②</span> 資料請求に対する発送のため</li>
        <li><span>③</span> 商品・サービス・イベントの案内のため</li>
      </ul>

      <h4 className="contact-form-policy-subtitle">２．第三者提供</h4>
      <p className="contact-form-policy-description">テクノネット・TMONYは、以下の場合を除きお客様の同意を得ないで個人データを第三者へ提供することはいたしません。</p>
      <ul className="contact-form-policy-list">
        <li><span>①</span> 法令に基づく場合</li>
        <li><span>②</span> 人の生命・身体・財産を保護するために必要で、本人から同意を得ることが難しい場合</li>
        <li><span>③</span> 国の機関や地方公共団体、その委託者などによる法令事務の遂行にあたって協力する必要があり、かつ本人の同意を得ることで事務遂行に影響が生じる可能性がある場合</li>
      </ul>
      
      <h4 className="contact-form-policy-subtitle">３．開示請求</h4>

      <p className="contact-form-policy-description">個人情報について、ご本人には開示・訂正・削除・利用停止を請求する権利があります。手続きにあたっては、ご本人確認のうえ対応させていただきますが、代理人の場合も可能です。詳細については、以下「個人情報相談窓口」へご連絡ください。</p>
      
      <p className="contact-form-policy-description">
        <small>
        個人情報相談窓口<br />
        〒164-0011<br />
        東京都中野区中央4-7-17<br />
        株式会社テクノネット 個人情報問合せ窓口<br />
        電話：<a href="tel:0367460080">03-6746-0080</a><br />
        FAX：03-6746-0090<br />
        e-mail：<a href="mailto:info@technonet.co.jp">info@technonet.co.jp</a><br /><br />
        ※お客様の利便性確保の為、株式会社テクノネットが代表して個人情報相談窓口を担い、株式会社TMONYはこれに従って対応します。
        </small>
      </p>
      <p className="contact-form-policy-footer">
        株式会社テクノネット<br />
        東京都中野区中央4-7-17<br /><br />
        株式会社TMONY<br />
        神奈川県横浜市青葉区美しが丘1-2 210
      </p>
    </>
  )
}

export default PrivacyPolicy;