export const SENDMAIL_URL = process.env.REACT_APP_SENDMAIL_URL as string;
export const SENDMAIL_API_KEY = process.env.REACT_APP_SENDMAIL_API_KEY as string;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

if (!SENDMAIL_URL) {
  throw new Error('Invalid SENDMAIL_URL');
}
if (!SENDMAIL_API_KEY) {
  throw new Error('Invalid SENDMAIL_API_KEY');
}
if (!RECAPTCHA_SITE_KEY) {
  throw new Error('Invalid RECAPTCHA_SITE_KEY');
}
