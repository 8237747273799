function Main() {

  return (
    <section className="main">
      <div className="container main-container">
        <h1 className="main-title">
          <span className="main-title-style01">バド</span>ミントンの<br />
          <span className="main-title-style02">スコア</span>入力からの結果速報を<br />
          <span className="main-title-style03">リアルタイム</span>に反映
        </h1>
        <p className="main-description">
          タブレットからの簡単入力で<br />
          コートサイド・場内モニターから<br className="visible-sp" />ウェブサイトまで<br />
          バドミントン試合の今をお届けできます</p>
      </div>
    </section>
  );
}

export default Main;