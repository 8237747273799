import { useState } from 'react';
import Slider from 'react-slick';
import csvImage from 'assets/images/birdscore_feature_csv.svg';
import printOut from 'assets/images/birdscore_feature_printout.svg';
import colorChange from 'assets/images/birdscore_feature_colorchange.svg';
import bannerImage from 'assets/images/birdscore_feature_banner.svg';
import SlideArrow from 'components/slider/Arrows';

type FeatureDataProps = {
  background: string | undefined;
  image: string;
  caption: string;
};

function Features() {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // speed: 2000,
    arrows: true,
    dots: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    swipeToSlide: true,
    variableWidth: true,
    focusOnSelect: true,
    cssEase: 'linear',
    prevArrow: <SlideArrow className="prev" onClick={() => Slider.prototype.slickNext()} />,
    nextArrow: <SlideArrow className="next" onClick={() => Slider.prototype.slickNext()} />,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
  };

  const FeatureDataSettings: FeatureDataProps[] = [
    {
      background: undefined,
      image: csvImage,
      caption: '選手リストの一括登録・書き出し',
    },
    {
      background: '#EFF8FF',
      image: printOut,
      caption: '結果表・トーナメント表のPDF出力',
    },
    {
      background: undefined,
      image: colorChange,
      caption: 'コートサイド・場内モニターのカラー設定',
    },
    {
      background: '#F9F9F9',
      image: bannerImage,
      caption: 'ウェブサイトのブランドバナー掲載',
    },
  ];

  return (
    <section className="feature">
      <h2 className="section-title feature-title">
        他にも
        <br />
        大会の運営をサポートする
        <br />
        機能が揃っています
      </h2>
      <Slider {...settings} className="feature-slide">
        {FeatureDataSettings.map((item, index) => {
          return (
            <div className="feature-slide-item" key={index}>
              <figure
                className={'feature-slide-item-image' + (index === currentSlide ? ' active' : '')}
                style={{
                  backgroundColor: `${item.background}`,
                  borderColor: `${item.background}`,
                }}
              >
                <img src={item.image} alt={item.caption} />
              </figure>
              <p className="feature-slide-item-caption">{item.caption}</p>
            </div>
          );
        })}
      </Slider>
    </section>
  );
}

export default Features;
